//SERVER
//if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // exports.url_server = 'http://localhost:8601'
//
//} else {
   exports.url_server = 'https://apicliente.fte.mx'
   //exports.url_server = "http://localhost:8601"
//}

exports.sgt_server = 'https://sgt.fte.mx'

exports.layout_config = {
    "navAnchor": "left",
    "navVariant": {
        "xs": "temporary",
        "sm": "temporary",
        "md": "temporary"
    },
    "navWidth": 256,
    "collapsible": {
        "xs": true,
        "sm": false,
        "md": false
    },
    "collapsedWidth": {
        "xs": 64,
        "sm": 64,
        "md": 64
    },
    "clipped": {
        "xs": true,
        "sm": false,
        "md": false
    },
    "headerPosition": {
        "xs": "relative",
        "sm": "relative",
        "md": "relative"
    },
    "squeezed": {
        "xs": true,
        "sm": true,
        "md": true
    },
    "footerShrink": {
        "xs": true,
        "sm": true,
        "md": true
    }
}

exports.table_options = {
    filterType : 'checkbox',
    selectableRows : 'simple',
    pagination : true,
    responsive : 'scroll',
    rowsPerPageOptions : [10, 50, 100],
    rowHover : true,
    fixedHeader : false,
    
    textLabels: {
        body: {
            noMatch: "No hay elementos",
            toolTip: "Ordenar",
        },
        pagination: {
            next: "Pagina siguiente",
            previous: "Pagina anterior",
            rowsPerPage: "Elementos por pagina:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "Filtrar",
        },
        filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "BORRAR",
        },
        viewColumns: {
            title: "Mostrar columnas",
            titleAria: "Mostrar/Ocultar columnas",
        },
        selectedRows: {
            text: "elemento(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar elementos seleccionados",
        },
    },
}

exports.main_subsections = [
    { id : 0, name : 'Información', url : '#telinfo' },
    { id : 1, name : 'Cuenta',   url : '#cuenta' },
  //  { id : 1, name : 'Facturacion',     url : '#facturacion' },
    { id : 2, name : 'Servicios',       url : '#servicios' },
 //   { id : 3, name : 'Consumos',       url : '#consumos' },
    //{ id : 4, name : 'Tienda',   url : '#tienda' },
   // { id : 5, name : 'Carrito',   url : '#carrito' },
    { id : 6, name : 'Recargas',   url : '#recargas' },
   
]


exports.home_sections = [
    { id : 0, name : 'INICIO', url : '#inicio' },
    { id : 1, name : 'TELEFONÍA MOVIL', url : '#telefonia' },
  //  { id : 2, name : 'TELEFONÍA FIJA', url : '#telefoniafija' },
  //  { id : 3, name : 'PORTABILIDAD',   url : '#portabilidad' },
  //  { id : 4, name : 'INTERNET FIJO',   url : '#internetfija' },
    { id : 5, name : 'COBERTURA',     url : '#cobertura' },
    { id : 4, name : 'RECARGAS',       url : '#recargasp' },
   // { id : 6, name : 'CONVIERTE EN DISTRIBUIDOR',       url : '#distribuidor' },
]

