import { layout_config } from '../config.js'

import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Container, CssBaseline, Box, Grid,Tabs, Tab, Modal } from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";
import { Root, Header, Nav, Content, Footer } from "mui-layout";
import { presets } from 'mui-layout';

import AppHeader from './AppHeader';
import TabsHeader from './TabsHeader';
import WebNavigator from './WebNavigator';
import WebFooter from './WebFooter';
import { home_sections } from '../config.js';
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import TabsWeb from './TabsWeb';
import Hidden from '@material-ui/core/Hidden';

import theme from '../theme.js';
import AssignmentIndIcon  from '@material-ui/icons/AssignmentInd';
import Login from '../pages/login'

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import  { UserContext }  from '../contexts/UserContext';
import Recuperacion from '../contexts/Webpages/Recuperacion';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
});

class WebLayout extends React.Component {

    state = {
        open : false,
        modalLogin : false,
        afiliaForm : false,
        textoLog : "Mi FTE"
    }

    static contextType = UserContext;

    componentDidMount() {
        this.context.login( (res) => {
            console.log(res,"login response home",this.context.user)
            if (res){
                let namer = res.nombre || ""
                this.setState({textoLog:'Bienvenido ' + namer})
                
            }else{
                this.setState({textoLog:'Mi FTE'})
            }
        });  
    }

    goLogin = () =>{
        //window.location = "/login"
        
        if(this.context.user){
            window.location = "/dash"
        }else{
            this.setState({modalLogin:true})
        }
        
        

    }

    toogleDrawer = () => {
        this.setState(prevState => ({
            open: !prevState.open
          }));
    }

    handleCloseModal = () =>{
        this.setState({modalLogin:false});
    }

    handleRecoveryModal = () =>{
        this.setState({modalRecovery:false, modalLogin:false});
    }

    openRecovery = () => {
        this.setState({modalRecovery:true});
    }

   

    render(){
        const {  tabs, tab } = this.props;

        let config = {
            ...layout_config,
            navWidth :  0,
            navWidth : 256 ,
            
        }

        return (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Root config={config} >

               <div style={{backgroundColor:'#fff', scrollX:'hidden'}}>
                <Container style={{paddingBottom:0,paddingRight:20, paddingLeft:20}} maxWidth="lg" >
                <Grid  container alignContent='center' justify='center'  style={{flex:1,flexDirection:'row'}}>

                <WebNavigator open ={this.state.open} retf={this.toogleDrawer} mifte={this.goLogin}/>

                <Modal  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                    aria-labelledby="login"
                    aria-describedby="credenciales"
                    className={theme.modal}
                    open={this.state.modalLogin}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    
                    }}
                >
                    <Fade in={this.state.modalLogin}>
                    <div className={theme.paper}>
                       <Login recoveryModal={this.openRecovery} />
                    </div>
                    </Fade>
                </Modal>


                <Modal  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                    aria-labelledby="login"
                    aria-describedby="recovery"
                    className={theme.modal}
                    open={this.state.modalRecovery}
                    onClose={this.handleRecoveryModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    
                    }}
                >
                    <Fade in={this.state.modalLogin}>
                    <div className={theme.paper}>
                       <Recuperacion closeHandle={this.handleRecoveryModal}  />
                    </div>
                    </Fade>
                </Modal>

               
                
                <Grid item style={{alignSelf:'flex-start'}} xs={1}>
                    <Hidden mdUp>
                        <MenuRounded  display='{xs}' onClick={ ()=>{this.toogleDrawer()}} style={{fill: "#000",marginRight:20,cursor:'pointer'}} /> 
                    </Hidden>
                </Grid>
                

                <Grid  container alignContent='center' justify='center'  style={{flex:1,flexDirection:'row'}}>
                    <Grid  item lg={12} md={12} style={{flexDirection:'row'}}>
                    
                 

                    <img src="logofte.svg" style={{height:44, marginTop:0}}/>
                    </Grid>
                </Grid>    

                    <Hidden smDown>
                    <Grid  item md={7} sm={12}>
                        <TabsWeb tab ={tab} tabs={home_sections}/>
                    </Grid>
                    </Hidden>

                    <Hidden smDown>
                    <Grid onClick={this.goLogin} item  md={2} xs={6}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexWrap: 'wrap',
                        marginTop : 12,
                        cursor :'pointer',
                        padding : 2,
                        borderRadius : 4,
                        paddingRight : 2,
                        paddingLeft : 2,
                        fontWeight : 'bold',
                        //backgroundColor: '#323b5c',
                        "&:hover": {
                            backgroundColor: '#323b5c !important',
                            color : "#fff !important"
                          }

                    }}>
                           {this.state.textoLog} <AssignmentIndIcon  style={{fill: "#eb6324", marginLeft:10 }}/>
                            </div>
                    </Grid>
                    </Hidden>

                    <Box display={{ md: "none"   }}>

                    </Box>

              
                </Grid>
                </Container> 

               
                </div>


                    <Container style={{paddingTop:0}}>
                        { this.props.children }
                    </Container>
       
               
                    <WebFooter />
              </Root>
            </ThemeProvider>
        )
    }
}

export default withStyles(styles)(WebLayout);